import { getOrderByClientApi } from "api/order";
import React, { useEffect, useState } from "react";
import { Order, OrderApiFilterParam } from "shared";
import OrderFilter from "ui/order/OrderFilter";
import OrderListTable from "ui/order/OrderList";
import { BodyWithClass } from "ui/typography";

const OrderHistory = () => {
	const [filter, setFilter] = useState<OrderApiFilterParam>({
		...Object(),
		orderStatus: "All",
	});

	const [orders, setOrders] = useState<Order[]>([]);

	useEffect(() => {
		getOrderByClientApi(filter)
			.then((response) => {
				setOrders(response);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [filter]);

	return (
		<div style={{ padding: 20, maxWidth: "100%", width: 900, margin: "auto" }}>
			<div style={{ margin: "20px" }}>
				<BodyWithClass text={"Order history "} className={""} />
			</div>
			<div>
				<OrderFilter filter={filter} setFilter={setFilter} />
			</div>
			<div style={{ height: 700, overflow: "auto", maxHeight: "calc(75vh)" }}>
				<OrderListTable orders={orders} emptyMessage={""} />
			</div>
		</div>
	);
};

export default OrderHistory;
