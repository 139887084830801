const product = "/product";

type ApiPath = {
  frontend: string;
  backend: string;
};

export const ProductPath = {
  product,
};

const api_prefix = "api";

const handlePath = ({ path }: { path: string }) => {
  const apiPath: ApiPath = {
    frontend: `${api_prefix}${ProductPath.product}/${path}`,
    backend: `/${path}`,
  };
  return apiPath;
};

export const Product_API_PATH = {
  new: handlePath({
    path: `new`,
  }),
  getProducts: handlePath({
    path: `/`,
  }),

  getProduct: (id: string) =>
    handlePath({
      path: `/${id}`,
    }),
  quotaById: (id: string) =>
    handlePath({
      path: `/quota/${id}`,
    }),
};
