import { isAxiosError } from "axios";
import {
  Order,
  OrderApiFilterParam,
  OrderPaymentVerification,
  API_PATH,
} from "shared";
import { GasOrderForm } from "shared/interface/form";
import { AxiosApi } from ".";

export const newOrderApi = async (form: GasOrderForm) => {
  try {
    const _data = await AxiosApi.post<Order>(
      API_PATH.order.newOrder.frontend,
      form
    );
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getOrderByClientApi = async (filter: OrderApiFilterParam) => {
  try {
    const _data = await AxiosApi.get<Order[]>(
      API_PATH.order.getOrdersByClient.frontend,
      {
        params: {
          ...filter,
        },
      }
    );
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getOrderByNumberApi = async (orderNumber: string) => {
  try {
    const _data = await AxiosApi.get<Order>(
      API_PATH.order.getOrderByNumber(orderNumber).frontend
    );
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const cancelOrderByIdApi = async (orderId: string) => {
  try {
    const _data = await AxiosApi.get<Order>(`/order/cancel/${orderId}`);
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const confirmOrderPaymentApi = async (orderId: string) => {
  try {
    const _data = await AxiosApi.get<Order>(
      `/order/confirm_payment/${orderId}`
    );
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const verifyOrderPaymentApi = async (orderNumber: number) => {
  try {
    const _data = await AxiosApi.get<OrderPaymentVerification>(
      API_PATH.order.verifyOrderPayment(orderNumber).frontend
    );
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getOrderByPaymentNumberApi = async (paymentNumber: number) => {
  try {
    const _data = await AxiosApi.get<Order>(
      `/order/payment_number/` + paymentNumber
    );
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};
