import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePaymentStore } from "store/payament";

const PaymentRedirectPage = () => {
  const navigate = useNavigate();
  const { ref, authorizationUrl, accessCode, orderNumber } = usePaymentStore();

  useEffect(() => {
    setTimeout(() => {
      navigate(`/payment/paystack/${ref}/${accessCode}/${orderNumber}`);
    }, 3000);
  }, []);

  return (
    <div style={{ textAlign: "center", padding: "50px" }}>
      <h2>Redirecting to Paystack for Payment</h2>
      <p>Please wait while we process your request...</p>
      <div style={{ marginTop: "20px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ margin: "auto", background: "none", display: "block" }}
          width="50px"
          height="50px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle
            cx="50"
            cy="50"
            fill="none"
            stroke="#3f51b5"
            strokeWidth="10"
            r="35"
            strokeDasharray="164.93361431346415 56.97787143782138"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;1"
              values="0 50 50;360 50 50"
            ></animateTransform>
          </circle>
        </svg>
      </div>
    </div>
  );
};

export default PaymentRedirectPage;
