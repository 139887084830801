import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { FC } from "react";
import { StepButton } from "@mui/material";

const HorizontalLinearStepper: FC<{
  steps: string[];
  setStep: (step: number) => void;
  step: number;
}> = ({ step, steps, setStep }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={step} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label} completed={step === steps.length}>
            <StepButton color="inherit" onClick={() => setStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default HorizontalLinearStepper;
