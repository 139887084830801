import React from "react";
import { Order, OrderStatus, OrderType } from "shared";
import { COLORS, NairaSign } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import useScreenTypeStore from "store/screenType";
import { ScreenType } from "interface";
import Loading from "ui/isLoading";
import { format } from "date-fns";

const OrderListTable: React.FC<{
  orders: Order[];
  emptyMessage: string;

  rowClicked?: (orderNumber: number, orderType: OrderType) => void;
  loading?: boolean;
  height?: string;
}> = ({ orders, emptyMessage, rowClicked, loading = false }) => {
  const navigate = useNavigate();
  const { screenType } = useScreenTypeStore();

  const handleStatusColor = (status: OrderStatus) => {
    if (status === OrderStatus.Processing) return COLORS.YellowLight;
    if (status === OrderStatus.Paid || status === OrderStatus.Delivered)
      return COLORS.StatusGreen;
    return "red";
  };
  const handleRowClicked = (orderNumber: number, orderType: OrderType) => {
    if (rowClicked) {
      rowClicked(orderNumber, orderType);
      return;
    }
    navigate(`/order/${orderNumber}`);
  };

  if (loading) return <Loading />;
  return (
    <div>
      <table style={{ width: "100%" }} className="order-table">
        <thead>
          <tr style={{ fontSize: 16 }}>
            <th className="poppins-regular ">Order</th>
            <th className="poppins-regular ">status </th>
            {screenType !== ScreenType.Mobile && (
              <>
                <th className="poppins-regular ">Date</th>
                <th className="poppins-regular ">Quantity</th>
                <th className="poppins-regular ">per kg</th>
              </>
            )}

            <th className="poppins-regular ">Amount</th>
          </tr>
        </thead>
        <tbody>
          {orders.length === 0 && (
            <tr>
              <td colSpan={5} className="empty-message">
                {emptyMessage}
              </td>
            </tr>
          )}
          {orders.map((order, index) => (
            <tr
              key={order.id}
              className={index % 2 === 0 ? "even-row" : "odd-row"}
              onClick={() =>
                handleRowClicked(order.orderNumber, order.orderType)
              }
            >
              <td>{"# " + order.orderNumber}</td>
              <td style={{ color: handleStatusColor(order.status) }}>
                {order.status}
              </td>
              {screenType !== ScreenType.Mobile && (
                <>
                  <td>{format(new Date(order.createdAt), "dd.MM.yyyy")}</td>
                  <td>{order.quantityKg + " Kg"}</td>
                  <td>{NairaSign + " " + order.pricePerKg}</td>
                </>
              )}

              <td>{NairaSign + " " + order.totalAmount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderListTable;
