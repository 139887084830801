import { isAxiosError } from "axios";
import { Product } from "shared/interface";
import { AxiosApi } from ".";
import { API_PATH } from "shared";

export const getProductsApi = async () => {
  try {
    const _data = await AxiosApi.get<Product[]>(API_PATH.product.getProducts.frontend);
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};
