import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

const Loading = ({ message = "Loading..." }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress color="primary" size={60} />
      <Typography
        variant="subtitle1"
        color="textSecondary"
        style={{ marginTop: 10 }}
      >
        {message}
      </Typography>
    </Box>
  );
};

export default Loading;
