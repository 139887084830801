import { Auth } from 'shared';
import { create } from 'zustand';

interface AuthStore extends Auth {
    authChecked: boolean, 
    setAuth: (auth: Auth) => void;
    setLogout: ()  => void,
    setAuthChecked : () => void

}

//Todo: add actual field
export const useAuthStore = create<AuthStore>()((set) => ({
    isLogin: false,
    isVerified: false,
    authChecked: false,
    setAuth: (auth: any) => set({ ...auth, authChecked: true }),
    setAuthChecked: () => set((state: AuthStore) => ({ ...state, authChecked: true })),
    setLogout: () => set({  isLogin: false,
        isVerified: false,
        authChecked: false
       })
}));

