import { Button, Divider } from "@mui/material";
import React, { useState, useRef } from "react";
import styles from "../../style.module.css";
import { CustomLink } from "../../ui/link";
import { useNavigate } from "react-router-dom";
import { InputField } from "../../ui/inputs";
import { Body, ErrorMessage, Header } from "../../ui/typography";
import { signupApi } from "../../api/auth";
import { ColorEnum } from "../../utils/constants";
import { CustomError, ErrorType } from "shared";
import { useAuthStore } from "store";

function SignupForm() {
	const [email, setEmail] = useState("");
	const [error, setError] = useState("");
	const [mobileNumber, setMobileNumber] = useState("");
	const numberRef = useRef("");
	const { setAuth } = useAuthStore();

	const navigate = useNavigate();

	const handleNumberChange = (value: string) => {
		console.log(mobileNumber.length);
		if (mobileNumber.length > 10) {
			if (!numberRef.current) {
				numberRef.current = value;
			}

			if (value.length > numberRef.current.length) {
				return;
			}
			numberRef.current = value;
		}

		const noTextRegex = /^[^a-zA-Z]*$/;
		if (noTextRegex.test(value)) {
			setMobileNumber(value);
		}
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (mobileNumber.length < 11) {
			setError("Mobile number is not complete");
			return;
		}
		let modifiedString = mobileNumber.replace(/^0/, "234");
		signupApi({ mobileNumber: modifiedString as any })
			.then((data) => {
				setAuth(data);
				navigate("/auth/verification/mobile", {
					state: { type: "mobile", mobileNumber },
				});
			})
			.catch((error) => {
				if (error) {
					const err = error as CustomError;
					if (err.type === ErrorType.UserExist) {
						setError("Account with this detail exist, pls login");
						return;
					}
				}
				setError("Something went wrong in creating account");
			});
	};

	return (
		<div
			style={{ height: "100%", margin: "auto", marginTop: "60px", width: 400 }}
			className="body-width"
		>
			<Header text={"Signup"} />

			<form onSubmit={handleSubmit} className={styles.formContainer}>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Body text={"Signup with your mobile number"} />
					<Divider style={{ maxWidth: "40%" }} />
				</div>
				<div style={{ display: "flex", padding: "auto" }}>
					<span style={{ margin: "auto" }}>+234</span>
					<InputField
						value={mobileNumber}
						label="Mobile number"
						placeholder="070000000"
						type="tel"
						required={true}
						error={error !== ""}
						onChange={handleNumberChange}
					/>
				</div>
				{/* <PasswordInputField
            value={confirmPassword}
            onChange={handleConfirmPassword}
            label="Confirm Password"
          /> */}

				<Button type="submit" fullWidth variant="contained">
					{" "}
					Continue
				</Button>

				<div
					style={{
						display: "flex",
						// justifyContent: "end",
						marginTop: "15px",
						gap: "5px",
					}}
				>
					<span>Already have an account? </span>

					<CustomLink to="/login" text="Login" color={ColorEnum.Blue} />
				</div>

				{error && <ErrorMessage text={error} />}
			</form>
		</div>
	);
}

export default SignupForm;
