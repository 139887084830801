import { Button, Divider } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styles from "../../style.module.css";
import { CustomLink } from "../../ui/link";
import { useLocation, useNavigate } from "react-router-dom";
import { InputField } from "../../ui/inputs";
import { Body, ErrorMessage, Header } from "../../ui/typography";
import { loginApi } from "../../api/auth";
import { ColorEnum } from "../../utils/constants";
import { CustomError, ErrorType } from "shared";
import { useAuthStore } from "../../store/index";

function Login() {
  const [error, setError] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const { setAuth, ...auth } = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();
  const numberRef = useRef("");

  useEffect(() => {
    if (auth.isLogin) {
      navigate(location.state.from || "/");
    }
  }, []);

  const handleNumberChange = (value: string) => {
    if (mobileNumber.length > 8) {
      if (!numberRef.current) {
        numberRef.current = value;
      }

      if (value.length > numberRef.current.length) {
        return;
      }
      numberRef.current = value;
    }

    if (mobileNumber.length > 11) return;
    const noTextRegex = /^[^a-zA-Z]*$/;
    if (noTextRegex.test(value)) {
      setMobileNumber(value);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (mobileNumber.length < 9) {
      setError("Mobile number is not complete");
      return;
    }
    let modifiedString = mobileNumber.replace(/^0/, "234");

    loginApi({ mobileNumber: modifiedString as any })
      .then((data) => {
        setAuth(data);
        navigate("/auth/verification/mobile", {
          state: {
            type: "mobile",
            mobileNumber,
            from: location.state?.from || "/",
          },
        });
      })
      .catch((error) => {
        if (error) {
          const err = error as CustomError;
          if (err.type === ErrorType.UserExist) {
            setError("Account with this detail exist, pls login");
            return;
          }
        }
        setError("Something went wrong in creating account");
      });
  };

  return (
    <div
      style={{ height: "100%", margin: "auto", marginTop: "60px", width: 400 }}
      className="body-width"
    >
      <Header text={"Login"} />

      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Body text={"Login with your mobile number"} />
          <Divider style={{ maxWidth: "40%" }} />
        </div>
        <div style={{ display: "flex", padding: "auto" }}>
          <span style={{ margin: "auto" }}>+234</span>
          <InputField
            value={mobileNumber}
            label="Mobile number"
            placeholder="070000000"
            type="tel"
            required={true}
            error={error !== ""}
            onChange={handleNumberChange}
          />
        </div>

        <Button type="submit" fullWidth variant="contained">
          {" "}
          Continue
        </Button>

        <div
          style={{
            display: "flex",
            // justifyContent: "end",
            marginTop: "15px",
            gap: "5px",
          }}
        >
          <span>Dont have an account? </span>

          <CustomLink to="/Signup" text="Signup" color={ColorEnum.Blue} />
        </div>

        {error && <ErrorMessage text={error} />}
      </form>
    </div>
  );
}

export default Login;
