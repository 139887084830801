import { isAxiosError } from "axios";
import {
  GasOrderForm,
  Order,
  Payment,
  PaymentInitData,
} from "shared/interface";
import { AxiosApi } from ".";
import { API_PATH } from "shared";
import { PaystackRetry } from "../../../node_modules/shared/interface/form";

export const paymentInitApi = async (form: GasOrderForm) => {
  try {
    const _data = await AxiosApi.post<PaymentInitData>(
      API_PATH.payment.initiate.frontend,
      form
    );
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const retryPaymentPaystackApi = async (form: PaystackRetry) => {
  try {
    const _data = await AxiosApi.post<PaymentInitData>(
      API_PATH.payment.paystackRetryPayment.frontend,
      form
    );
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getPaymentByOrderApi = async (orderId: string) => {
  try {
    const _data = await AxiosApi.get<Payment>(`/payment/order/${orderId}`);
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getPaymentByApi = async () => {
  try {
    const _data = await AxiosApi.get<Payment>(`/payment/`);
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const orderPaymentByWalletApi = async (form: GasOrderForm) => {
  try {
    const _data = await AxiosApi.post<Order>(`/payment/wallet`, form);
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getByPaymentNumberApi = async (paymentNumber: string) => {
  try {
    const _data = await AxiosApi.get<Payment>(API_PATH.payment.getByPaymentNumber(paymentNumber).frontend);
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};
