import React, { useState } from "react";
import { Card, CardContent, Typography, IconButton, Box } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Wallet } from "shared";

export const WalletCard: React.FC<Wallet> = ({ ...wallet }) => {
	const [showSensitiveData, setShowSensitiveData] = useState(false);
	const maskCardNumber = (cardNumber: string): string => {
		const last4 = cardNumber.slice(-4);
		return `•••• •••• •••• ${last4}`;
	};

	const maskPin = (pin: string): string => {
		return "•".repeat(pin.length);
	};

	return (
		<Card
			elevation={3}
			sx={{
				background: "linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)",
				color: "white",
				position: "relative",
				minHeight: "200px",
				overflow: "visible",
				"&::before": {
					content: '""',
					position: "absolute",
					top: 0,
					right: 0,
					bottom: 0,
					left: 0,
					background:
						"linear-gradient(45deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%)",
					borderRadius: "inherit",
				},
			}}
		>
			<CardContent>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mb: 3,
					}}
				>
					<Typography variant="h4" sx={{ fontWeight: "bold" }}>
						{wallet.balance}
					</Typography>
					<Typography
						variant="h6"
						sx={{
							fontFamily: '"Roboto Mono", monospace',
							letterSpacing: "0.1em",
						}}
					>
						UG Wallet
					</Typography>
				</Box>

				<Typography
					variant="h6"
					sx={{
						mb: 2,
						fontFamily: '"Roboto Mono", monospace',
						letterSpacing: "0.1em",
					}}
				>
					{wallet.walletNumber}
				</Typography>

				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Typography
						variant="body1"
						sx={{ mr: 1, fontFamily: '"Roboto Mono", monospace' }}
					>
						PIN: {maskPin(wallet.pin.toString())}
					</Typography>
					<IconButton
						size="small"
						onClick={() => setShowSensitiveData(!showSensitiveData)}
						sx={{ color: "white" }}
					>
						{showSensitiveData ? <VisibilityOff /> : <Visibility />}
					</IconButton>
				</Box>

				{/* <Typography
					variant="body2"
					sx={{
						position: "absolute",
						bottom: 16,
						right: 16,
						fontFamily: '"Roboto Mono", monospace',
						letterSpacing: "0.1em",
					}}
				>
					{balance.currenc}
				</Typography> */}
			</CardContent>
		</Card>
	);
};
