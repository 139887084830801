import { Divider } from "@mui/material";
import { formatDistance } from "date-fns";
import React, { FC } from "react";
import { Order, OrderStatus, OrderType, PaymentStatus } from "shared";
import { Btn } from "ui/button";
import CustomItemFlex from "ui/Items";
import { COLORS, NairaSign } from "utils/constants";

const OrderDetail: FC<Order> = ({ ...order }) => {
	const handleStatusColor = (status: OrderStatus) => {
		if (status === OrderStatus.Processing) return COLORS.YellowLight;
		if (status === OrderStatus.Paid || status === OrderStatus.Delivered)
			return COLORS.StatusGreen;
		return "red";
	};

	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				flexDirection: "column",
				gap: "10px",
			}}
		>
			<CustomItemFlex
				text={"Order Number : "}
				value={"# " + order.orderNumber}
				valueStyle={{ color: COLORS.GreyMediumDark }}
			/>
			<CustomItemFlex
				text={"Status: "}
				value={order.status}
				valueStyle={{ color: handleStatusColor(order.status) }}
			/>
			<CustomItemFlex
				text={"Date:"}
				valueStyle={{ color: COLORS.GreyMediumDark }}
				value={formatDistance(new Date(order.createdAt), new Date(), {
					addSuffix: true,
				})}
			/>
			{order.deliveredDate && (
				<CustomItemFlex
					text={"Delivered At :"}
					valueStyle={{ color: COLORS.GreyMediumDark }}
					value={formatDistance(new Date(order.createdAt), new Date(), {
						addSuffix: true,
					})}
				/>
			)}
			<div>
				{order.orderType !== OrderType.Product && (
					<div>
						<CustomItemFlex
							valueStyle={{ color: COLORS.GreyMediumDark }}
							text={"Order type: "}
							value={"Gas Order"}
						/>
						<CustomItemFlex
							valueStyle={{ color: COLORS.GreyMediumDark }}
							text={"Quantity: "}
							value={order.quantityKg + "Kg"}
						/>
						<CustomItemFlex
							valueStyle={{ color: COLORS.GreyMediumDark }}
							text={"Price per kg: "}
							value={NairaSign + order.pricePerKg}
						/>
						<CustomItemFlex
							valueStyle={{ color: COLORS.GreyMediumDark }}
							text={"Amount: "}
							value={NairaSign + order.gasAmount}
						/>
					</div>
				)}
				<div>
					{order.product &&
						order.product.map((item, index) => (
							<div key={index}>
								<CustomItemFlex
									text={"Item: "}
									valueStyle={{ color: COLORS.GreyMediumDark }}
									value={item.name}
								/>
								<CustomItemFlex
									text={"Quantity: "}
									value={item.quantity}
									valueStyle={{ color: COLORS.GreyMediumDark }}
								/>
								<CustomItemFlex
									text={"Size: "}
									value={item.price}
									valueStyle={{ color: COLORS.GreyMediumDark }}
								/>
								<CustomItemFlex
									text={"Price: "}
									valueStyle={{ color: COLORS.GreyMediumDark }}
									value={NairaSign + item.price}
								/>
								<CustomItemFlex
									text={"Amount: "}
									valueStyle={{ color: COLORS.GreyMediumDark }}
									value={NairaSign + item.total}
								/>
							</div>
						))}
				</div>
			</div>
			<Divider />
			<div style={{ marginTop: "20px" }}>
				{order.paymentStatus === PaymentStatus.Success &&
				order.paymentSuccess ? (
					<Btn text={" Re-order"} style={{ width: "200px" }} />
				) : (
					<Btn text={"Pay order now!"} style={{ width: "200px" }} />
				)}
			</div>
		</div>
	);
};

export default OrderDetail;
