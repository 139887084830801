import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	RouterProvider,
	Outlet,
} from "react-router-dom";

import Home from "../components/Home";
import SignupForm from "../components/auth/Signup";
import Navbar from "../ui/navbar/Navbar";
import AboutUs from "../ui/about";
import OTPVerification from "../components/auth/Verification";
import Login from "../components/auth/Login";
import DriverSignupPage from "../components/Rider";
import NewOrderPage from "components/order/NewOrder";
import PaymentRedirectPage from "components/payment/PaystackRedirect";
import PaymentPage from "components/payment/Paystack";
import PaymentCancelled from "components/payment/PaystackCancelled";
import PaymentSuccess from "components/payment/success";
import OrderDetailPage from "components/order/OrderDetail";
import Products from "components/product/Products";
import OrderHistory from "components/order/OrderHistory";
import WalletPage from "components/wallet/Wallet";

const Root = () => {
	return (
		<div
			style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
		>
			<div>
				<Navbar />{" "}
			</div>
			<div style={{ flex: 1 }}>
				<Outlet />
			</div>
			{/* <div>
				<Footer />
			</div> */}
		</div>
	);
};

let router = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" element={<Root />}>
			{/* other routes here */}
			<Route path="/" element={<Home />} />
			<Route path="/signup" element={<SignupForm />} />
			<Route path="/auth/verification/:type" element={<OTPVerification />} />
			<Route path="/about" element={<AboutUs />} />
			<Route path="/login" element={<Login />} />
			<Route path="/rider" element={<DriverSignupPage />} />
			<Route path="/order/new" element={<NewOrderPage />} />
			<Route path="/order/:number" element={<OrderDetailPage />} />
			<Route path="/order/history" element={<OrderHistory />} />
			<Route path="/products" element={<Products />} />
			<Route path="/wallet" element={<WalletPage />} />
			<Route path="/payment/redirect" element={<PaymentRedirectPage />} />
			<Route
				path="/payment/paystack/:ref/:accessCode/:orderNumber"
				element={<PaymentPage />}
			/>
			<Route
				path="/payment/cancelled/:id/:paymentNumber/:purpose"
				element={<PaymentCancelled />}
			/>

			<Route
				path="/payment/failed/:id/:paymentNumber/:purpose"
				element={<PaymentCancelled />}
			/>
			<Route
				path="/payment/success/:id/:paymentNumber/:purpose"
				element={<PaymentSuccess />}
			/>
		</Route>
	)
);

export default function RouterItem() {
	return <RouterProvider router={router} />;
}
