import React, { useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { SearchLocation as Location } from "shared";

import {
  Box,
  InputAdornment,
  Popper,
  Typography,
  debounce,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Btn } from "./button";

interface LocationOption {
  label: string;
  key: number;
  lat: number;
  lon: number;
  placeId: number;
}

interface LocationData {
  display_name: string;
  lat: number;
  lon: number;
  place_id: number;
}
interface InputFieldProps {
  disabled?: boolean;
  allowUseLocation?: boolean;
  onSelect?: (location: Location | null) => void;
}

const LocationSearchInput: React.FC<InputFieldProps> = ({
  onSelect,
  disabled = false,
  allowUseLocation = true,
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<LocationOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const anchorRef = useRef<HTMLDivElement>(null);
  const [hoveredOption, setHoveredOption] = useState<number | null>(null);
  const [selectedLocation, setSelectedLocation] =
    useState<LocationOption | null>(null);

  //Todo: format the name  before rendering it
  // const handleAddressNmae = (addess: string) => {
  //   "1B, Larsgårdsringen, Nørvegjerdet, Ålesund, Møre og Romsdal, 6009, Norway";
  // };

  const handelClearSelect = () => {
    setSelectedLocation(null);

    if (onSelect) {
      onSelect(null);
    }
  };

  const debounceFetch = React.useMemo(
    () =>
      debounce(
        async (
          inputValue: string,
          setLoading: React.Dispatch<React.SetStateAction<boolean>>,
          setOptions: React.Dispatch<React.SetStateAction<LocationOption[]>>
        ) => {
          console.log(inputValue);
          const test = "";
          try {
            setLoading(true); // Set loading to true before making the request
            const response = await axios.get<LocationData[]>(
              `https://nominatim.openstreetmap.org/search?format=json&q=${inputValue}&city=&${test}`
            );
            const data = response.data.map((result, index) => ({
              key: result.place_id,
              label: result.display_name,
              lat: result.lat,
              lon: result.lon,
              placeId: result.place_id,
            }));

            setOptions(data);
          } catch (error) {
            console.error("Error fetching locations:", error);
          } finally {
            setLoading(false); // Set loading to true before making the request
          }
        },
        900
      ),
    []
  );

  const handleInputChange = (
    // value: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (!open) {
      setOpen(true);
    }
    setInputValue(value);
    debounceFetch(value, setLoading, setOptions);
  };

  const handleSelect = (value: LocationOption) => {
    setInputValue(value.label);
    setSelectedLocation(value);
    if (onSelect && value) {
      onSelect({
        address: value.label,
        lat: value.lat,
        long: value.lon,
      });
    }
  };

  const handleUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          axios
            .get<LocationData[]>(
              `https://nominatim.openstreetmap.org/search?format=json&lat=${latitude}&lon=${longitude}`
            )
            .then((response) => {
              console.log("the response ", response);
              setOptions(
                response.data.map((result, index) => ({
                  key: index,
                  label: result.display_name,
                  lat: result.lat,
                  lon: result.lon,
                  placeId: result.place_id,
                }))
              );
            })
            .catch((error) => {
              console.error("Error fetching locations:", error);
            });
        },
        (error) => {
          console.error("Error getting user's location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <Box component="div">
      <div style={{ display: "block" }}>
        <TextField
          ref={anchorRef}
          type="address"
          label="Search Location"
          value={inputValue}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: selectedLocation ? (
              <InputAdornment onClick={handelClearSelect} position="start">
                <Close />
              </InputAdornment>
            ) : null,
          }}
          style={{ backgroundColor: "white", width: "100%" }}
          // onFocus={() => setOpen(true)}
          onBlur={() => setOpen(false)}
        />

        {allowUseLocation ? (
          <Btn text={"Use my location"} onClick={handleUserLocation} />
        ) : (
          ""
        )}
        {loading ? <CircularProgress color="inherit" size={20} /> : null}
        <Popper
          anchorEl={anchorRef.current}
          open={open}
          style={{
            width: 400, //anchorRef.current?.clientWidth,
            backgroundColor: "white",
            padding: "10px",
            borderRadius: 4,
          }}
        >
          {options.map((option) => (
            <div
              key={option.key}
              onMouseEnter={() => setHoveredOption(option.key)}
              onMouseLeave={() => setHoveredOption(null)}
              onMouseDown={() => {
                handleSelect(option);
              }}
              style={{
                cursor: "pointer",
                backgroundColor:
                  hoveredOption === option.key ? "#f5f5f5" : "transparent",
                transition: "background-color 0.3s",
              }}
            >
              <Typography
                onClick={() => {
                  console.log("is logging here");
                }}
                sx={{ pt: 2 }}
              >
                {option.label}
              </Typography>
            </div>
          ))}
        </Popper>
      </div>
    </Box>
  );
};

export default LocationSearchInput;
