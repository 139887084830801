import React from "react";
import { useState, useEffect } from "react";
import ProductCard from "ui/card/ProductCard";
import { Product } from "shared";
import { BodyWithClass } from "ui/typography";
import { getProductsApi } from "api/product";

const Products = () => {
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    getProductsApi()
      .then((response) => {
        console.log("here", response);
        setProducts(response);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }, []);

  return (
    <div style={{ textAlign: "center", marginTop: 50 }}>
      <div>
        <BodyWithClass text={"Products"} className={"poppins-bold"} />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
          gap: 20,
          marginTop: 20,
        }}
      >
        {products.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default Products;
