import { getWalletApi } from "api/wallet";
import React, { useEffect, useState } from "react";
import { Wallet } from "shared";
import { WalletCard } from "ui/wallet/WalletCard";
import WalletInfo from "ui/wallet/WalletInfo";

const WalletPage = () => {
  const [wallet, setWallet] = useState<Wallet | null>(null);

  useEffect(() => {
    getWalletApi()
      .then((data) => {
        setWallet(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      {wallet ? (
        <div>
          <WalletCard {...wallet} />
        </div>
      ) : (
        <WalletInfo />
      )}
    </div>
  );
};

export default WalletPage;
