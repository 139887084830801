import { DeliveryOption, HomeDeliveryType } from "shared";
import { RadioFieldProps, SelectorOptionProps } from "ui/inputs";

export const HEIGHTS = {
  footer: 100,
  navBar: 60,
};
export enum ColorEnum {
  Blue = "#144CC3",
}

export const DeliveryDropdownList = [
  {
    label: "Pickup",
    value: DeliveryOption.PickUp,
  },
  {
    label: "Home delivery (coming soon)",
    value: DeliveryOption.HomeDelivery,
    // disabled: true,
  },
  {
    label: "Pickup by someone",
    value: DeliveryOption.PickUpBySomeOne,
  },
];

export const HomeDeliveryTypeDropdownList :  SelectorOptionProps[] = [
  {
    label: "One way delivery",
    value: HomeDeliveryType.Oneway,
    showInfo: true,
    infoContent: "this the content about the delivtion optin"
  },
  {
    label: "Double way delivery",
    value: HomeDeliveryType.DoubleWay,
    showInfo: true,
   infoContent: "this the content about the delivtion optin"
  },

];

export const NairaSign = "₦";


export const ICON_NAMES = {
  SuccessBlue : "blue_success"
}

export const COLORS = {
  Blue: "#144CC3",
  Grey100: "#C1C1C1",
  Grey86:  "#D9DBDC",
  Grey20:  "#333333",
  StatusGrey : "#949494",
  StatusRed : "#A62408",
  StatusBlue: "#3091F2",
  BlueLight: "#43BCD1",
  RedLight: "#EA542E",
  YellowLight: "#F7D277",
  StatusGreen: "#08A657",
  GreyMediumDark: "#616161"


}