import { PaymentPurpose } from "shared";
import { create } from "zustand";

interface Parameter {
  authorizationUrl: string | null;
  orderNumber: number | null;
  ref: string | null;
  accessCode: string | null;
  purpose: PaymentPurpose | null;
  paymentNumber: string | null;
}
interface PaymentStore extends Parameter {
  setPaymentDetails: (param: Parameter) => void;
  clearPaymentDetails: () => void;
}

export const usePaymentStore = create<PaymentStore>()((set) => ({
  authorizationUrl: null,
  orderNumber: null,
  ref: null,
  purpose: null,
  accessCode: null,
  paymentNumber: null,
  setPaymentDetails: (param: Parameter) => set(() => ({ ...param })),
  clearPaymentDetails: () =>
    set(() => ({
      authorizationUrl: null,
      orderNumber: null,
      ref: null,
      purpose: null,
    })),
}));
