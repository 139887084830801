import { useEffect, useState } from "react";
import { DeliveryOption, GasOrderForm } from "shared";
import useScreenTypeStore from "store/screenType";
import { InputField, RadioField } from "ui/inputs";
import { ScreenType } from "../../interface";
import { useAppDataStore } from "store/appData";
import { BodyWithClass } from "ui/typography";
import {
  DeliveryDropdownList,
  HomeDeliveryTypeDropdownList,
  NairaSign,
} from "utils/constants";
import HorizontalLinearStepper from "ui/Step";
import { Btn } from "ui/button";
import LocationSearchInput from "ui/location";
import { isValidPhoneNumber } from "utils/helper";
import OrderPreview from "../../ui/order/OrderPreview";
import { paymentInitApi } from "api/paymnet";
import { usePaymentStore } from "store/payament";
import { useNavigate } from "react-router-dom";

interface ErrorsTypes {
  quantity: boolean;
  pickupPerson: boolean;
  deliveryType: boolean;
}
const maxStep = 3;
const NewOrderPage = () => {
  const [form, setForm] = useState<GasOrderForm>({
    ...Object(),
    deliveryOption: DeliveryOption.PickUp,
  });

  const [step, setStep] = useState(0);
  const [errorType, setErrorType] = useState<ErrorsTypes>(Object);
  const [phoneNumberErr, setPhoneNumberErr] = useState("");

  const { screenType } = useScreenTypeStore();
  const { appData } = useAppDataStore();
  const { setPaymentDetails } = usePaymentStore();
  const navigate = useNavigate();

  //   const { client } = useClientStore();

  const handleNextStep = (_step: number) => {
    if (step === 0) {
      if (!form.quantity) {
        setErrorType({ ...errorType, quantity: true });

        return;
      }

      if (form.quantity < 1) {
        setErrorType({ ...errorType, quantity: true });
        return;
      }
      if (form.deliveryOption === DeliveryOption.PickUpBySomeOne) {
        if (
          !form.pickupByMobileNumber ||
          !isValidPhoneNumber(form.pickupByMobileNumber)
        ) {
          setErrorType({ ...errorType, pickupPerson: true });
          setPhoneNumberErr("phone number is missing or not valid");
          return;
        }
      }
    }

    if (step === 1) {
      if (form.deliveryOption === DeliveryOption.HomeDelivery) {
        if (!form.homeDeliveryType) {
          setErrorType({ ...errorType, deliveryType: true });
          return;
        }
      }
    }
    setStep(_step);
  };
  useEffect(() => {
    if (form.quantity && form.quantity > 1) {
      setErrorType({ ...errorType, quantity: false });
    }
    if (form?.deliveryOption === DeliveryOption.PickUpBySomeOne) {
      if (
        form.pickupByMobileNumber &&
        isValidPhoneNumber(form.pickupByMobileNumber)
      ) {
        setErrorType({ ...errorType, pickupPerson: false });
        setPhoneNumberErr("");
      }
    }
    if (
      form.deliveryOption === DeliveryOption.HomeDelivery &&
      form.homeDeliveryType
    ) {
      setErrorType({ ...errorType, deliveryType: false });
    }
  }, [form]);

  const placeOrder = () => {
    paymentInitApi(form)
      .then((response) => {
        setPaymentDetails({
          authorizationUrl: response.authorizationUrl,
          orderNumber: response.orderNumber,
          ref: response.paymentReference,
          purpose: response.purpose,
          paymentNumber: response.paymentNumber as any,
          accessCode: response.accessCode,
        });
        navigate("/payment/redirect");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        maxWidth: "700px",
        width: "100%",
        paddingRight: screenType === ScreenType.Desktop ? "64px" : "30px",
        paddingLeft: screenType === ScreenType.Desktop ? "64px" : "30px",
        paddingTop: screenType === ScreenType.Desktop ? "64px" : "30px",
        flexDirection: "column",
        margin: "auto",
        gap: "50px",
        marginBottom: "50px",
      }}
    >
      <div>
        <div style={{ display: "flex", gap: 20 }}>
          <BodyWithClass
            text={"Pending Orders"}
            className={"poppins-semibold"}
            style={{ fontSize: "18px" }}
          />
          <BodyWithClass
            text={(appData?.currentPrice as any) + NairaSign}
            className={"poppins-semibold"}
            style={{ fontSize: "18px" }}
          />
        </div>
        <div>
          <HorizontalLinearStepper
            steps={["Quantity", "Delivery", "Address", "Preview"]}
            setStep={(step) => handleNextStep(step)}
            step={step}
          />
        </div>
        <form action="">
          {step === 0 && (
            <>
              <div>
                <InputField
                  label={"Quantity"}
                  placeholder="Order quantity kg"
                  // value={form?.quantity || null}
                  type={"number"}
                  onChange={(value) =>
                    setForm({ ...form, quantity: value as any })
                  }
                  error={errorType?.quantity}
                />
              </div>
              <div style={{ display: "flex" }}>
                <RadioField
                  id="46"
                  label={"Choose delivery option"}
                  value={form.deliveryOption}
                  options={DeliveryDropdownList}
                  onChange={(value) =>
                    setForm({ ...form, deliveryOption: value })
                  }
                />
              </div>

              {form.deliveryOption === DeliveryOption.PickUpBySomeOne && (
                <div>
                  <InputField
                    label={"pickup person mobile number"}
                    placeholder="070848488409"
                    value={form?.pickupByMobileNumber}
                    type={"tel"}
                    onChange={(value) =>
                      setForm({ ...form, pickupByMobileNumber: value as any })
                    }
                    error={errorType?.pickupPerson}
                    helperText={phoneNumberErr}
                  />
                </div>
              )}
            </>
          )}
          {step === 1 && (
            <>
              {form.deliveryOption === DeliveryOption.HomeDelivery ? (
                <div style={{ display: "flex" }}>
                  <RadioField
                    id="1"
                    label={"Home delivery type"}
                    // value={form.homeDeliveryType}
                    options={HomeDeliveryTypeDropdownList}
                    error={errorType.deliveryType}
                    onChange={(value) =>
                      setForm({ ...form, homeDeliveryType: value })
                    }
                    helperText={
                      errorType.deliveryType
                        ? "Delivery type is required, pls choose one"
                        : ""
                    }
                  />
                  <div style={{ display: "flex", gap: 20 }}>
                    <BodyWithClass
                      text={"Estimated delivery cost "}
                      className={"poppins-semibold"}
                      style={{ fontSize: "18px" }}
                    />
                    <BodyWithClass
                      text={appData?.otuokeDeliveryCost + NairaSign}
                      className={"poppins-semibold"}
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                </div>
              ) : (
                <BodyWithClass
                  text={"You choose to pickup the order at the store"}
                  className={"poppins-semibold"}
                  style={{ fontSize: "18px" }}
                />
              )}
            </>
          )}
          {step === 2 && (
            <div>
              {form.deliveryOption === DeliveryOption.HomeDelivery ? (
                <div>
                  <LocationSearchInput
                    onSelect={(location) =>
                      setForm({
                        ...form,
                        deliveryLocation: {
                          lat: location?.lat as any,
                          long: location?.long as any,
                          address: location?.address as any,
                          description: "",
                        },
                      })
                    }
                  />
                </div>
              ) : (
                <BodyWithClass
                  text={"Pickup the order at the store"}
                  className={"poppins-semibold"}
                  style={{ fontSize: "18px" }}
                />
              )}
            </div>
          )}

          {step === maxStep && (
            <OrderPreview order={form} placeOrder={placeOrder} />
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 50,
            }}
          >
            <Btn
              text={"Previous"}
              isDisabled={step <= 0}
              onClick={() => handleNextStep(step - 1)}
            />
            <Btn
              text={"Next "}
              onClick={() => handleNextStep(step + 1)}
              isDisabled={step === maxStep}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewOrderPage;
