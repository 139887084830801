import { MenuItem } from "@mui/material";
import { CustomLink } from "../link";
import { useClientStore } from "store";

const Products: React.FC<{ isFlex?: boolean }> = ({ isFlex = false }) => {
	const style = {
		display: isFlex ? "flex" : "block",
		color: "white !important",
		gap: "50px",
	};
	const { client } = useClientStore();

	return (
		<div style={style}>
			{client ? (
				<>
					<MenuItem
						style={{
							backgroundColor: "black",
							borderRadius: "10px",
							minWidth: "90px",
						}}
					>
						<CustomLink text={"Products"} to={"/products"} color="white" />
					</MenuItem>
				</>
			) : (
				<>
					<MenuItem
						style={{
							backgroundColor: "black",
							borderRadius: "10px",
							minWidth: "90px",
						}}
					>
						<CustomLink text={"Rider"} to={"/rider"} color="white" />
					</MenuItem>
					<MenuItem
						style={{
							backgroundColor: "black",
							borderRadius: "10px",
							minWidth: "90px",
						}}
					>
						<CustomLink text={"Products"} to={"/products"} color="white" />
					</MenuItem>
					<MenuItem
						style={{
							backgroundColor: "black",
							borderRadius: "10px",
							minWidth: "90px",
						}}
					>
						<CustomLink text={"About us"} to={"/about"} color="white" />
					</MenuItem>
				</>
			)}
		</div>
	);
};

export default Products;
