import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { PaymentStatus } from "shared";
import { usePaymentStore } from "store/payament";
import PaystackPop from "@paystack/inline-js";
import Info from "ui/Info";
import { Btn, ButtonProps } from "ui/button";
import { COLORS } from "utils/constants";
import { BodyWithClass } from "ui/typography";

enum ActionType {
  Close = "Close",
  Success = "Success",
  Error = "Error",
}
interface PopAction {
  title: string;
  message: string;
  type: ActionType;
  actions: ButtonProps[];
}
const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [retryPayment, setRetryPayment] = useState(false);
  const [popAction, setPopAction] = useState<PopAction | null>(null);
  const { accessCode, orderNumber } = useParams();

  const { authorizationUrl, ref, purpose, paymentNumber } = usePaymentStore();

  useEffect(() => {
    //Todo: check the payment reference if the payment has been made
    if (accessCode) {
      const paystackPopup = new PaystackPop();
      const te = paystackPopup.resumeTransaction(accessCode, {
        onCancel: (data: any) => {
          console.log("cancel", data);
          setPopAction({
            title: "Canceling your payment?",
            actions: [
              {
                style: { backgroundColor: "red", color: "white" },
                onClick: () => {
                  navigate(`/order/${orderNumber}`);
                },
                text: "Close payment",
              },
              {
                style: { backgroundColor: COLORS.Blue, color: "white" },
                onClick: () => setRetryPayment(!retryPayment),
                text: "Continue  to Pay",
              },
            ],
            type: ActionType.Close,
            message: "Are you sure you want to cancel your  payment ? ",
          });
        },
        onSuccess: (transaction: {
          id: number;
          reference: string;
          message: string;
          status: PaymentStatus;
        }) => {
          // setPopAction({
          // 	action: () => {},
          // 	actionType: ActionType.Success,
          // 	messages: "",
          // });
          setTimeout(() => {
            navigate(`/order/${orderNumber}`);
          }, 10000);
          setPaymentSuccess(true);
        },
        onLoad: (e) => {
          // console.log(e);
        },
        onError: () => {
          setPopAction({
            title: "Payment error",
            actions: [
              {
                style: { backgroundColor: "red", color: "white" },
                onClick: () => {
                  navigate(`/order/${orderNumber}`);
                },
                text: "Close payment",
              },
              {
                style: { backgroundColor: COLORS.Blue, color: "white" },
                onClick: () => {},
                text: "Try again",
              },
            ],
            type: ActionType.Close,
            message: "Something went wrong with the payment page !",
          });
        },
      });
      console.log(te);
    }
  }, [accessCode, retryPayment]);

  useEffect(() => {
    if (popAction) {
      // Hide the Paystack popup when showing the Info modal
      const iframe = document.querySelector("iframe");
      if (iframe) {
        iframe.classList.add("paystack-hidden");
      }
    }
  }, [popAction]);

  return (
    <div style={{ height: "100vh", backgroundColor: "white" }}>
      {paymentSuccess ? (
        <div>
          <div>
            <BodyWithClass
              text={
                "Payment success, you will be redirect to the order page in 1  minute  or click blow to go to order page"
              }
              className={"poppins-semibold"}
              style={{ fontSize: "22px", color: COLORS.Blue }}
            />
          </div>
          <Btn
            text={"View wallet"}
            onClick={() => navigate(`/order/${orderNumber}`)}
          />
        </div>
      ) : (
        <Info
          title={popAction?.title as any}
          show={popAction !== null}
          setShow={() => setPopAction(null)}
          description={popAction?.message as any}
          actions={popAction?.actions || []}
        />
      )}
    </div>
  );
};

export default PaymentPage;
