import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActions,
  Button,
  Divider,
} from "@mui/material";
import { Product } from "shared";

const ProductCard: React.FC<{ product: Product }> = ({
  product: { name, description, imageUrl, price },
}) => {
  if (!imageUrl) {
    imageUrl = "/image/gas_cylinder.png";
  }
  return (
    <Card elevation={2} style={{ maxWidth: "100%", height: 300 }}>
      <CardMedia
        component="img"
        alt={name}
        style={{ marginTop: "10px", maxHeight: "150px" }}
        image={imageUrl}
        title={name}
      />
      <Divider style={{ marginTop: "5px" }} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {name}
        </Typography>
        {/* <Typography variant="body2" color="textSecondary" component="p">
          {description}
        </Typography> */}
        <Typography variant="h6" component="p">
          {price}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" color="primary">
          Add to Cart
        </Button>
        <Button size="small" color="primary">
          View Details
        </Button>
      </CardActions>
    </Card>
  );
};

export default ProductCard;
