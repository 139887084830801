import React, { FC, useState } from "react";
import { OrderApiFilterParam, OrderStatus } from "shared";
import { Box, Button, MenuItem, Select, TextField } from "@mui/material";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

const OrderFilter: FC<{
	filter: OrderApiFilterParam;
	setFilter: (filter: OrderApiFilterParam) => void;
}> = ({ filter, setFilter }) => {
	const resetFilter = () => {
		setFilter({ ...Object(), orderStatus: "All" });
	};

	const navigate = useNavigate();

	return (
		<Box
			sx={{
				display: "flex",
				gap: 2,
				p: 2,
				// border: "1px solid #ccc",
				justifyContent: "space-between",
				borderRadius: "8px",
				backgroundColor: "#f9f9f9",
			}}
		>
			<Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
				<TextField
					label="From Date"
					style={{ height: "34px" }}
					type="date"
					className="input-type"
					value={filter?.fromDate}
					onChange={(e) =>
						setFilter({ ...filter, fromDate: e.target.value as any })
					}
				/>
				<TextField
					label="To Date"
					type="date"
					className="input-type"
					value={filter?.toDate}
					onChange={(e) =>
						setFilter({ ...filter, toDate: e.target.value as any })
					}
				/>
				<Select
					style={{ height: "36px", padding: "3px" }}
					value={filter?.orderStatus}
					className="input-type"
					onChange={(e) =>
						setFilter({ ...filter, orderStatus: e.target.value as any })
					}
				>
					<MenuItem value={"All"}>
						<em>All Statuses</em>
					</MenuItem>
					<MenuItem value={OrderStatus.NotPaid}>Not paid</MenuItem>
					<MenuItem value={OrderStatus.Paid}>Paid</MenuItem>
					<MenuItem value={OrderStatus.Delivered}>Delivered</MenuItem>
					<MenuItem value={OrderStatus.Cancelled}>Cancelled</MenuItem>
					<MenuItem value={OrderStatus.Processing}>Processing</MenuItem>
				</Select>
			</Box>

			<Box sx={{ display: "flex", gap: 2 }}>
				<Button variant="outlined" onClick={resetFilter}>
					Reset
				</Button>
				<Button variant="outlined" onClick={() => navigate("/order/new")}>
					New order
				</Button>
			</Box>
		</Box>
	);
};

export default OrderFilter;
