export enum ProductType {
  Cylinder = "Cylinder",
  Burner = "Burner",
  GasStove = "GasStove",
}

export interface Product {
  id: string;
  name: string;
  price: number;
  size: number;
  total: number
  color: string;
  imageUrl: string;
  quantity: number;
  typeCode: string;
  productType: ProductType;
  description: string;
  variant: ProductVariant[]
}

export interface ProductSales extends Product {
  clientId: string;
  orderDate: Date;
  deliverDate: Date;
}

export interface ProductVariant {
  id: string;
  price: number;
  color: string;
  quantity: number;
}