import React from "react";
import { Btn } from "ui/button";
import { BodyWithClass } from "ui/typography";

const WalletInfo = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyItems: "center",
        justifyContent: "center",
        height: "500px",
        maxHeight: "100%",
      }}
    >
      <div>
        <div>
          <BodyWithClass
            text={"No wallet  has been "}
            className={"poppins-bold"}
            style={{
              fontSize: "20px",
              textAlign: "center",
              marginBottom: "20px",
            }}
          />
          <BodyWithClass
            text={"Reason to create a wallet"}
            className={"poppins-medium"}
            style={{
              fontSize: "19px",
              textAlign: "center",
              marginBottom: "20px",
            }}
          />
        </div>
        <div style={{ margin: "auto", textAlign: "center" }}>
          <ul
            style={{
              textAlign: "left",
              marginBottom: "20px",
              lineHeight: "1.6",
              width: "fit-content",
              margin: "auto",
            }}
          >
            <li>Installment payment for easier financial management.</li>
            <li>Convenient and faster ordering.</li>
            <li>Send money to your wallet securely.</li>
            <li>Transfer money from your wallet to your bank account.</li>
            <li>And many more features to simplify your transactions.</li>
          </ul>
        </div>
        <div style={{ marginTop: "30px" }}>
          <Btn text={"Create Wallet now!"} />
        </div>
      </div>
    </div>
  );
};

export default WalletInfo;
