import { CSSProperties, FC } from "react";
import { BodyWithClass } from "./typography";

const CustomItemFlex: FC<{
	containerStyle?: CSSProperties;
	text: string;
	textClass?: string;
	value: any;
	valueClass?: string;
	textStyle?: CSSProperties;
	valueStyle?: CSSProperties;
}> = ({
	containerStyle,
	value,
	text,
	valueClass,
	textClass,
	textStyle,
	valueStyle,
}) => {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				...containerStyle,
			}}
		>
			<BodyWithClass
				text={text}
				className={textClass + " "}
				style={textStyle}
			/>
			<BodyWithClass
				text={value}
				className={valueClass + ""}
				style={valueStyle}
			/>
		</div>
	);
};

export default CustomItemFlex;
