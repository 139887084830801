import {
	AppBar,
	Avatar,
	Box,
	Container,
	IconButton,
	Link,
	Toolbar,
	Typography,
} from "@mui/material";

import React, { useEffect } from "react";
import Account from "./account";
import Products from "./products";
import useScreenTypeStore from "../../store/screenType";
import { HEIGHTS } from "../../utils/constants";
import { getAppData } from "../../api/app";
import { useAppDataStore } from "../../store/appData";
import { useAuthStore, useClientStore } from "store";
import { cookieAuthApi } from "api/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomError } from "shared";

const Navbar: React.FC = () => {
	const { updateScreenType } = useScreenTypeStore();
	const { appData, loadedAt, setData } = useAppDataStore();
	const { setAuth, setAuthChecked, ...auth } = useAuthStore();
	const { setClient } = useClientStore();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		updateScreenType(); // Check on mount
		window.addEventListener("resize", updateScreenType);
		if (!appData) {
			getAppData()
				.then((data) => {
					console.log(data);
					setData(data);
				})
				.catch((err) => {
					console.log(err);
					navigate("/", {
						state: {
							from: location.pathname || "/",
						},
					});
				});
		}

		if (!auth.isLogin) {
			cookieAuthApi()
				.then((response) => {
					setAuth(response.auth);
					setClient(response.client);
				})
				.catch((err) => {
					setAuthChecked();
					navigate("/", {
						state: {
							from: location.pathname || "/",
						},
					});
				})
				.finally(() => {});
		}

		return () => window.removeEventListener("resize", updateScreenType);
	}, [updateScreenType, appData]);

	useEffect(() => {
		updateScreenType(); // Check on mount
		window.addEventListener("resize", updateScreenType);
		if (!appData) {
			getAppData()
				.then((data) => {
					setData(data);
				})
				.catch((err) => {
					console.log(err);
				});
		}

		return () => window.removeEventListener("resize", updateScreenType);
	}, [updateScreenType, appData]);

	return (
		<AppBar position="static" style={{ height: HEIGHTS.navBar }}>
			<Container maxWidth="xl" style={{ maxWidth: "calc(1280px + 64px* 2)" }}>
				<Toolbar
					disableGutters
					style={{ display: "flex", justifyContent: "space-between" }}
				>
					<Link href="/">
						<div style={{ display: "flex", width: "200px", height: "2" }}>
							<IconButton>
								<Avatar
									style={{
										backgroundColor: "black",
									}}
									src="/icon/ug_transparent.png"
									alt="Urbanline Gas"
								>
									UG
								</Avatar>
							</IconButton>
							<Typography style={{ margin: "auto" }} variant="h6" color="white">
								Urbanline Gas
							</Typography>
						</div>
					</Link>
					<Box sx={{ display: { xs: "none", md: "flex" } }}>
						<Products isFlex={true} />
					</Box>
					<div>
						<Account />
					</div>
				</Toolbar>
			</Container>
		</AppBar>
	);
};

export default Navbar;
