import {
	Avatar,
	Box,
	Divider,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	MenuList,
	Tooltip,
	useMediaQuery,
	useTheme,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";

import React, { useEffect } from "react";
import { Btn } from "../button";
import { useLocation, useNavigate } from "react-router-dom";
import Products from "./products";
import { useAuthStore, useClientStore } from "store";

const Account: React.FC = () => {
	const [isUserLoggedIn, setIsUserLoggedIn] = React.useState(false);
	const { client } = useClientStore();
	const auth = useAuthStore();
	const navigate = useNavigate();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	useEffect(() => {
		if (client && auth.isLogin) {
			setIsUserLoggedIn(auth.isLogin);
		}
	}, [client, auth]);

	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
		null
	);

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	useEffect(() => {
		handleCloseUserMenu();
	}, [isMobile]);

	return (
		<>
			{isMobile ? (
				<Box sx={{ right: 0 }}>
					{isUserLoggedIn ? (
						<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
							<Avatar alt="user" />
						</IconButton>
					) : (
						<IconButton
							size="large"
							edge="end"
							color="inherit"
							onClick={handleOpenUserMenu}
							aria-label="menu"
							sx={{ mr: -2, color: "white" }}
						>
							<MenuIcon />
						</IconButton>
					)}

					<Menu
						sx={
							{
								// width: {
								// 	xs: "95%",
								// 	sm: "auto",
								// },
							}
						}
						anchorEl={anchorElUser}
						anchorOrigin={{
							vertical: "top",
							horizontal: "left",
						}}
						keepMounted
						transformOrigin={{
							vertical: "top",
							horizontal: isMobile ? "left" : "right",
						}}
						open={Boolean(anchorElUser)}
						onClick={() => {}}
						onClose={handleCloseUserMenu}
						className="menu-mobile"
					>
						<div style={{ marginRight: "20px" }}>
							{isUserLoggedIn ? (
								<MenuList onClick={handleCloseUserMenu}>
									<MenuItem>
										<Avatar />
										<ListItemText style={{ marginLeft: "10px" }}>
											Profile
										</ListItemText>
									</MenuItem>
									<Divider />
									<ProductItems />
									<Divider />
									<ProfileItems />
									<Divider />
									<MenuItem>
										<ListItemText>Logout</ListItemText>
									</MenuItem>
								</MenuList>
							) : (
								<MenuList onClick={handleCloseUserMenu}>
									<MenuItem onClick={() => navigate("/rider")}>
										<ListItemText>Riders</ListItemText>
									</MenuItem>
									<MenuItem onClick={() => navigate("/products")}>
										<ListItemText>Products</ListItemText>
									</MenuItem>
									<MenuItem onClick={() => navigate("/about")}>
										<ListItemText>About</ListItemText>
									</MenuItem>
									<MenuItem onClick={() => navigate("/login")}>
										<ListItemText>Login</ListItemText>
									</MenuItem>
								</MenuList>
							)}
						</div>
					</Menu>
				</Box>
			) : (
				<Box
					sx={{
						flexGrow: 0,
					}}
				>
					{isUserLoggedIn ? (
						<>
							<Tooltip title="Open settings">
								<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
									<Avatar alt="user" />
								</IconButton>
							</Tooltip>
							<Menu
								sx={{
									backgroundColor: "transparent",
									mt: "30px",
									// width: {
									// 	xs: "95%",
									// 	sm: "auto",
									// },
								}}
								anchorEl={anchorElUser}
								anchorOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								open={Boolean(anchorElUser)}
								onClose={handleCloseUserMenu}
								className="large-screen-menu"
							>
								<div style={{ marginRight: "20px" }}>
									<MenuList onClick={handleCloseUserMenu}>
										<ProfileItems />
										<Divider />
										<MenuItem>
											<ListItemText>Logout</ListItemText>
										</MenuItem>
									</MenuList>
								</div>
							</Menu>
						</>
					) : (
						<div
							style={{
								flexGrow: 0,
								display: "flex",
								justifyContent: "flex-end",
								maxWidth: "100%",
								gap: "20px",
							}}
						>
							<Btn
								text="Login "
								style={{ backgroundColor: "black" }}
								onClick={() => navigate("/login")}
							/>
							<Btn
								text="Signup"
								style={{ backgroundColor: "black" }}
								onClick={() => navigate("/signup")}
							/>
						</div>
					)}
				</Box>
			)}
		</>
	);
};

export default Account;

const ProfileItems = () => {
	const navigate = useNavigate();

	return (
		<>
			<MenuItem onClick={() => navigate("/wallet")}>
				<ListItemIcon>
					<AccountBalanceWalletOutlinedIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText>Wallet</ListItemText>
			</MenuItem>

			<MenuItem onClick={() => navigate("/order/history")}>
				<ListItemIcon>
					<HistoryOutlinedIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText>Order history</ListItemText>
			</MenuItem>
			<MenuItem>
				<ListItemIcon>
					<LocalShippingOutlinedIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText>Pickup</ListItemText>
			</MenuItem>

			<MenuItem>
				<ListItemIcon>
					<ManageAccountsOutlinedIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText>Profile</ListItemText>
			</MenuItem>

			<MenuItem>
				<ListItemIcon>
					<SettingsSuggestOutlinedIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText>Setting</ListItemText>
			</MenuItem>
		</>
	);
};

const ProductItems = () => {
	const navigate = useNavigate();
	return (
		<>
			<MenuItem onClick={() => navigate("order/new")}>
				<ListItemIcon>
					<InventoryOutlinedIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText>New order</ListItemText>
			</MenuItem>

			<MenuItem onClick={() => navigate("/products")}>
				<ListItemIcon>
					<StorefrontOutlinedIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText>Products</ListItemText>
			</MenuItem>
		</>
	);
};
