import { isAxiosError } from "axios";
import {
  Payment,
  PaymentInitData,
  VerifyOrder,
  Wallet,
} from "shared/interface";
import { AxiosApi } from ".";
import { API_PATH } from "shared";

export const getWalletApi = async () => {
  try {
    const _data = await AxiosApi.get<Wallet|null>(API_PATH.wallet.walletByClient.frontend);
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const addFundToWalletApi = async (amount: number) => {
  try {
    const _data = await AxiosApi.post<PaymentInitData>(`/wallet/add_fund`, {
      amount,
    });
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const verifyWalletPaymentApi = async (form: VerifyOrder) => {
  try {
    const _data = await AxiosApi.post<Payment>(`/wallet/verify_payment`, form);
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};
