import React, { FC } from "react";
import { DeliveryOption, GasOrderForm } from "shared";
import { useAppDataStore } from "store/appData";
import { Btn } from "ui/button";
import { BodyWithClass } from "ui/typography";
import { NairaSign } from "utils/constants";

const OrderPreview: FC<{ order: GasOrderForm; placeOrder: () => void }> = ({
	order,
	placeOrder,
}) => {
	const { appData } = useAppDataStore();

	return (
		<div
			style={{
				maxWidth: 500,
				margin: "auto",
				marginTop: "40px",

				backgroundColor: "#b8dbe159",
				padding: "16px",
				paddingTop: "6px",
				borderRadius: "8px",
			}}
		>
			<div>
				<BodyWithClass text={"Order preview"} className={"poppins-bold "} />
				<div>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<BodyWithClass
							text={"Quantity"}
							className={"poppins-light-italic  "}
						/>
						<BodyWithClass
							text={`${order.quantity} kg`}
							className={"poppins-light-italic  "}
						/>
					</div>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<BodyWithClass
							text={"Cost per kg"}
							className={"poppins-light-italic  "}
						/>
						<BodyWithClass
							text={` ${NairaSign} ${appData?.currentPrice}`}
							className={"poppins-light-italic  "}
						/>
					</div>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<BodyWithClass
							text={"Delivery Option"}
							className={"poppins-light-italic  "}
						/>
						<BodyWithClass
							text={order.deliveryOption}
							className={"poppins-light-italic  "}
						/>
					</div>
					{order.deliveryOption === DeliveryOption.HomeDelivery && (
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<BodyWithClass
								text={"Home delivery type"}
								className={"poppins-light-italic  "}
							/>
							<BodyWithClass
								text={order.homeDeliveryType as any}
								className={"poppins-light-italic  "}
							/>
						</div>
					)}
					{order.deliveryOption === DeliveryOption.PickUpBySomeOne && (
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<BodyWithClass
								text={"Pickup person number"}
								className={"poppins-light-italic  "}
							/>
							<BodyWithClass
								text={order.pickupByMobileNumber as any}
								className={"poppins-light-italic  "}
							/>
						</div>
					)}
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<BodyWithClass
							text={"Estimated delivery fee "}
							className={"poppins-light-italic  "}
						/>
						<BodyWithClass
							text={` ${NairaSign} ${order.deliveryFee || 0}`}
							className={"poppins-light-italic"}
						/>
					</div>
					{order.product?.map((item, index) => (
						<div key={index}>
							<BodyWithClass
								text={item.name}
								className={"poppins-light-italic"}
							/>
							<BodyWithClass
								text={`${NairaSign} ${item.price}`}
								className={"poppins-light-italic"}
							/>
						</div>
					))}
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<BodyWithClass text={"Total"} className={"poppins-light-italic"} />
						<BodyWithClass
							text={`${NairaSign} ${handleTotal(
								order,
								appData?.currentPrice as any
							)}`}
							className={"poppins-light-italic"}
						/>
					</div>
				</div>
			</div>
			<div>
				<Btn text={"Order Now !"} onClick={placeOrder} />
			</div>
		</div>
	);
};

export default OrderPreview;

const handleTotal = (order: GasOrderForm, gasPrice: number) => {
	console.log(order);
	let total = order.quantity * gasPrice;
	if (order.deliveryFee) {
		total = total + order.deliveryFee;
	}
	if (order.product) {
		order.product.forEach((item) => {
			total = total + item.price;
		});
	}
	return total;
};
