import React, { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { cancelOrderByIdApi, getOrderByNumberApi } from "api/order";
import { Order, PaymentPurpose } from "shared";
import { retryPaymentPaystackApi } from "api/paymnet";
import { usePaymentStore } from "store/payament";
import OrderDetail from "ui/order/OrderDetail";

const PaymentCancelled: FC = () => {
  const [searchParams] = useSearchParams();
  const [order, setOrder] = useState<Order | null>(null);
  const [orderCancelled, setOrderCancelled] = useState(false);
  const navigate = useNavigate();

  const orderId = searchParams.get("orderId");
  const ref = searchParams.get("ref");
  const { setPaymentDetails } = usePaymentStore();

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const fetchedOrder = await getOrderByNumberApi(orderId as string);
        setOrder(fetchedOrder);
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    };

    if (orderId) fetchOrder();
  }, [orderId]);

  const retryPayment = () => {
    retryPaymentPaystackApi({
      purpose: PaymentPurpose.Order,
      paymentReference: ref as any,
      orderNumber: orderId as any,
    })
      .then((response) => {
        setPaymentDetails({
          authorizationUrl: response.authorizationUrl,
          orderNumber: response.orderNumber,
          ref: response.paymentReference,
          purpose: response.purpose,
          paymentNumber: response.paymentNumber as any,
          accessCode: response.accessCode,
        });
        navigate(`/payment/paystack/" + response.authorizationUrl`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cancelOrder = async () => {
    try {
      const cancelledOrder = await cancelOrderByIdApi(orderId as string);
      setOrder(cancelledOrder);
      setOrderCancelled(true);
    } catch (error) {
      console.error("Error cancelling order:", error);
    }
  };

  const newOrder = () => {
    navigate("/order");
  };

  return (
    <div className="payment-cancelled">
      <div className="content">
        {order ? (
          <OrderDetail {...order} />
        ) : (
          <div>
            <h2>Thank you for placing an order!</h2>
            <p>Your payment for the order is about to be cancelled.</p>
          </div>
        )}
        <div className="actions">
          {orderCancelled ? (
            <button onClick={newOrder}>Add new order</button>
          ) : (
            <>
              <button onClick={retryPayment}> Retry Pay Now</button>
              <button onClick={cancelOrder}>Cancel Order</button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentCancelled;
