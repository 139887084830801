import React, { FC, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
// import { MaterialIcons } from "@mui/icons-material";
import { getOrderByNumberApi } from "api/order";
import { Order, Payment } from "shared";
import { Btn } from "ui/button";
import Loading from "ui/isLoading";
import Info from "ui/Info";
import { PaymentPurpose } from "shared";
import { getByPaymentNumberApi } from "api/paymnet";
import { COLORS, ICON_NAMES } from "utils/constants";
import { BodyWithClass } from "ui/typography";

const PaymentSuccess: FC = () => {
	const { id, paymentNumber, purpose } = useParams();
	const [order, setOrder] = useState<Order | null>(null);
	const [payment, setPayment] = useState<Payment | null>(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (purpose === PaymentPurpose.Order) {
					const data = await getOrderByNumberApi(id as string);
					setOrder(data);
				}
				if (purpose === PaymentPurpose.Wallet) {
					//Todo: handle wallet
				}

				// const payment = await getByPaymentNumberApi(paymentNumber as any);
				// setPayment(payment);
			} catch (err) {
				setError(true);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [id]);

	if (loading) return <Loading />;

	return (
		<div
			style={{
				height: "90vh",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			{error ? (
				<Info
					title="Payment Error"
					description="Something went wrong with the Order payment."
					subDescription="The order will be ready soon. If not, contact support."
					show={error}
					setShow={() => setError(false)}
					actions={[
						{
							onClick: () => navigate("/"),
							text: "Back to Home",
						},
					]}
				/>
			) : (
				<div
					style={{
						height: 600,
						maxHeight: "100%",
						margin: "auto",
					}}
				>
					<div>
						<img src={`/icon/${ICON_NAMES.SuccessBlue}.svg`} alt="success" />
						<div>
							<BodyWithClass
								text={"Payment successful!"}
								className={"poppins-semibold"}
								style={{ fontSize: "22px", color: COLORS.Blue }}
							/>
						</div>
					</div>
					{order ? (
						<div>
							{purpose === PaymentPurpose.Order ? (
								<div>
									<div style={{ display: "flex", gap: 5 }}>
										<BodyWithClass
											text={`Yor payment for order number`}
											className={"poppins-medium "}
											style={{ fontSize: "14px", color: COLORS.Grey100 }}
										/>
										<BodyWithClass
											text={` #${order?.orderNumber}`}
											className={"poppins-semibold-italic "}
											style={{ fontSize: "14px", color: COLORS.Blue }}
										/>
										<BodyWithClass
											text={` is completed`}
											className={"poppins-medium "}
											style={{ fontSize: "14px", color: COLORS.Grey100 }}
										/>
									</div>
									<Btn
										text={"View Order "}
										onClick={() => navigate(`/order/${order?.orderNumber}`)}
									/>
								</div>
							) : (
								<div>
									<BodyWithClass
										text={"TODO : ADD MESSAGE FOR WALLET"}
										className={"poppins-medium "}
										style={{ fontSize: "14px", color: COLORS.Grey100 }}
									/>
									<Btn
										text={"View wallet"}
										onClick={() => navigate(`/wallet/${order?.id}`)}
									/>
								</div>
							)}
						</div>
					) : (
						<Loading />
					)}
				</div>
			)}
		</div>
	);
};

export default PaymentSuccess;
